import { useEffect } from "react";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import React from "react";
import { getBookingStepByTag, getBookingStepByUrl } from "../../utils";
import { BookingJourneyStep, StepperTag } from "../../interfaces/booking";
import {
  generateBookingQueryString,
  useNextRouterPathname,
  isBookingSearchQueryParamsExistsInUrl,
} from "../../utils/common";
import { JourneyTypes } from "../../interfaces/stepper";
import { FHStepper } from "../../components/SemanticTheme/FHStepper";
import { LocationAvailabilitySummary_S as LocationAvailabilitySummary } from "@generated/types";
import { setActiveBookingStepByUrlAction } from "@/store/actions";
import {
  useActiveStepperPersistedSelector,
  useSearchLocationsSelector,
  useBookingQueryStringSelector,
  useBookingSearchDateRange,
  useBookingSearchStateSelector,
  useActiveStepSelector,
  useSiteSettingsSelector,
  useBookingSearchGuests,
  useBookingSearchDda,
  useStickySelector,
  useIsAuthenticated,
  useIsAuthenticating,
} from "@/store/selectors";

type BookingJourneyStepperProps = {
  hideMobileNav?: boolean;
  disableNext?: boolean;
  disablePrevious?: boolean;
};

export const BookingJourneyStepperComponent = ({
  hideMobileNav,
  disableNext,
  disablePrevious,
}: BookingJourneyStepperProps) => {
  const dispatch = useDispatch();
  const history = useRouter();
  const {
    fhBookingSteps,
    activeBookingStepIndex,
    errorMessage,
    loaded,
    additionalBookingJourneyData,
    isLoggedInOnExtrasPage,
  } = useSiteSettingsSelector();
  const isAuthenticated = useIsAuthenticated();
  const isAuthenticating = useIsAuthenticating();
  const isLoggedIn =
    isLoggedInOnExtrasPage || isAuthenticated || isAuthenticating;
  const expFR260BookingSteps = fhBookingSteps;

  const loggedInFHBookingSteps = expFR260BookingSteps?.filter(
    (x) => x.tag !== "login",
  );
  const { cabin } = useStickySelector();

  const dateRange = useBookingSearchDateRange();
  const guests = useBookingSearchGuests();
  const dda = useBookingSearchDda();
  const activeStep = useActiveStepSelector();
  const queryData = useBookingQueryStringSelector();
  const { activeJourneyStep: persistedActiveJourneyStep, activeJourney } =
    useActiveStepperPersistedSelector();
  const { data: searchLocationsData } = useSearchLocationsSelector();
  const { selectedLocationIds: selectedCabinsData } =
    useBookingSearchStateSelector();
  const cabinStep = getBookingStepByTag(StepperTag.cabins, fhBookingSteps);
  const locationStep = getBookingStepByTag(
    StepperTag.locations,
    fhBookingSteps,
  );
  const isExtrasPage = getBookingStepByTag(
    StepperTag.extras,
    fhBookingSteps,
  )?.completed;

  useEffect(() => {
    if (loaded && fhBookingSteps) {
      let url = useNextRouterPathname(history);
      let redirectUrl = "";
      let locationIds: string[] = [];
      if (queryData && queryData.selectedLocationIds) {
        const stepRedirectingTo = getBookingStepByUrl(fhBookingSteps);
        const { selectedLocationIds } = queryData;

        switch (stepRedirectingTo?.tag) {
          case StepperTag.locations:
            if (selectedLocationIds.length === 1) {
              redirectUrl = cabinStep?.url || "";
            }
            break;
          case StepperTag.cabins:
            if (selectedLocationIds.length > 1) {
              redirectUrl = locationStep?.url || "";
            }
            break;
        }

        locationIds = selectedLocationIds;
      }

      if (redirectUrl && locationIds.length > 0) {
        const queryString = generateBookingQueryString({
          startDate:
            queryData?.dateRange.startDateISO || dateRange.startDateISO || "",
          endDate:
            queryData?.dateRange.endDateISO || dateRange.endDateISO || "",
          locationIds,
          guests: queryData?.guests || guests,
          dda: queryData?.dda || dda,
        });

        history.push({
          pathname: redirectUrl,
          search: queryString,
        });
      } else {
        const stepperUrlIndex = fhBookingSteps.findIndex(
          (step: BookingJourneyStep) => step?.url === url,
        );

        if (!isBookingSearchQueryParamsExistsInUrl()) {
          if (activeJourney !== JourneyTypes.bookingJourney) {
            history.push("/");
          } else if (stepperUrlIndex > 0 && persistedActiveJourneyStep === 0) {
            history.push(fhBookingSteps[persistedActiveJourneyStep]?.url || "");
          }
        }
        dispatch(setActiveBookingStepByUrlAction(url));
      }
    }
  }, [loaded, fhBookingSteps, persistedActiveJourneyStep]);

  const handleDisableSteps =
    activeStep?.tag === StepperTag.confirm ||
    activeBookingStepIndex ===
      (fhBookingSteps?.length && fhBookingSteps.length - 1);
  if (!(fhBookingSteps && loaded && !errorMessage)) {
    return null;
  }

  return (
    <FHStepper
      hideMobileNav={hideMobileNav}
      disableNext={disableNext}
      disablePrevious={disablePrevious}
      steps={isLoggedIn ? loggedInFHBookingSteps : expFR260BookingSteps}
      activeStepIndex={
        isLoggedIn && isExtrasPage
          ? activeBookingStepIndex - 1
          : activeBookingStepIndex
      }
      disableSteps={handleDisableSteps}
      onStepClick={(url) => {
        if (url) {
          const stepClicked = fhBookingSteps.find((step) => step?.url === url);

          let search: string | undefined,
            searchLocationIds: string[] | undefined;

          switch (stepClicked?.tag) {
            case StepperTag.locations:
              if (searchLocationsData?.locations?.length > 1) {
                searchLocationIds = (
                  searchLocationsData?.locations ||
                  ([] as LocationAvailabilitySummary[])
                ).map((location) => location.locationId);
              } else {
                searchLocationIds = [selectedCabinsData[0]];
              }
              break;
            case StepperTag.cabins:
              if (cabin?.locationId) {
                searchLocationIds = [cabin.locationId];
              } else if (
                additionalBookingJourneyData?.cabins ||
                selectedCabinsData?.length === 1
              ) {
                searchLocationIds = additionalBookingJourneyData?.cabins
                  ?.locationId
                  ? [additionalBookingJourneyData.cabins.locationId]
                  : [selectedCabinsData[0]];
              }
              break;
          }
          if (searchLocationIds?.length > 0) {
            search = generateBookingQueryString({
              startDate: dateRange.startDateISO || "",
              endDate: dateRange.endDateISO || "",
              locationIds: searchLocationIds,
              guests: queryData?.guests || guests,
              dda: queryData?.dda || dda,
            });
          }
          history.push({
            pathname: url,
            search,
          });
        }
      }}
    />
  );
};
