import React, { PropsWithChildren } from "react";
import Error from "@mui/icons-material/Error";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { FHMessage } from "../FHMessage";

const useStyles = makeStyles((theme) => ({
  errorIcon: {
    color: theme.palette.error.main,
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  text: {
    color: theme.palette.error.main,
  },
}));

export type ErrorMessageProps = {
  message?: string;
  componentMessage?: JSX.Element;
  className?: string;
};

export const ErrorMessage: React.FC<ErrorMessageProps> = ({
  message,
  componentMessage,
  className,
}: PropsWithChildren<ErrorMessageProps>) => {
  const classes = useStyles();

  const elMessage = message ? (
    <Typography className={classes.text}>{message}</Typography>
  ) : (
    componentMessage
  );

  return (
    <FHMessage
      icon={<Error className={classes.errorIcon} />}
      className={className}
      message={elMessage}
    />
  );
};
