import React from "react";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import Grid from "@mui/material/Grid";

const useStyles = (
  hasBorderRadius: boolean,
  showGlentressStaticMessaging: boolean,
) =>
  makeStyles((theme) => ({
    root: {
      backgroundColor: showGlentressStaticMessaging
        ? "#F0F5F0"
        : theme.palette.divider,
      padding: theme.spacing(1.5, 1.5),
      borderRadius: hasBorderRadius ? theme.spacing(1) : "none",
    },
  }))();

export type FHMessageProps = {
  message: JSX.Element | string | undefined;
  className?: string;
  hasBorderRadius?: boolean;
  icon?: JSX.Element;
  messageAlignment?: string;
  showGlentressStaticMessaging?: boolean;
};

export const FHMessage = ({
  message,
  className,
  hasBorderRadius = false,
  icon,
  messageAlignment,
  showGlentressStaticMessaging,
}: FHMessageProps) => {
  const classes = useStyles(hasBorderRadius, showGlentressStaticMessaging);

  return (
    <div className={classNames(classes.root, className)}>
      <Grid
        container
        spacing={1}
        alignItems="center"
        wrap="nowrap"
        justifyContent={messageAlignment}
        sx={{ textAlign: messageAlignment }}
      >
        {icon && <Grid item>{icon}</Grid>}
        <Grid item>{message}</Grid>
      </Grid>
    </div>
  );
};
