import React from "react";
import { makeStyles } from "@mui/styles";
import { Dialog, DialogContent, Typography } from "@mui/material";
import { FHButton } from "../FHButton";
import { FHNextImage, ImageLoader } from "../FHNextImage";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      width: "100%",
      maxWidth: 544,
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(1.6),
      },
    },
  },
  dialogGroup: {
    padding: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2.4),
    },
  },
  titleContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "& h2": {
      fontSize: 32,
      lineHeight: "40px",
      [theme.breakpoints.down("sm")]: {
        fontSize: 24,
        lineHeight: "32px",
      },
    },
  },
  descriptionContainer: {
    padding: 0,
    marginBottom: theme.spacing(2),
  },
  actionsContainer: {
    justifyContent: "center",
    "& button": {
      maxWidth: 294,
      [theme.breakpoints.up("sm")]: {
        minWidth: 294,
      },
    },
  },
  button: {
    padding: theme.spacing(0.4, 1),
    height: 40,
    fontSize: 16,
    borderRadius: 7,
  },
}));

export type FHModalProps = {
  open: boolean;
  messageIcon?: string;
  handleClose: (event?: React.SyntheticEvent, reason?: string) => void;
  title?: string;
  description?: JSX.Element;
  confirmCTALabel?: string;
  imageLoader?: ImageLoader;
  isStaticImg?: boolean;
  iconWidth?: number;
  iconHeight?: number;
};

export const FHTimerModal: React.FC<FHModalProps> = ({
  open,
  messageIcon,
  handleClose,
  title,
  description,
  confirmCTALabel,
  imageLoader,
  isStaticImg,
  iconHeight,
  iconWidth,
}: FHModalProps) => {
  const classes = useStyles();

  return (
    <Dialog className={classes.root} open={open} onClose={handleClose}>
      <div className={classes.dialogGroup}>
        {messageIcon && (
          <FHNextImage
            alt=""
            src={messageIcon}
            height={iconHeight}
            width={iconWidth}
            isStaticImg={isStaticImg}
            loader={imageLoader}
          />
        )}
        {title && (
          <div className={classes.titleContainer}>
            <Typography variant="h2">
              <strong data-testid="FHModalTitle">{title}</strong>
            </Typography>
          </div>
        )}
        {description && (
          <DialogContent className={classes.descriptionContainer}>
            {description}
          </DialogContent>
        )}
        {confirmCTALabel && (
          <div className={classes.actionsContainer}>
            <FHButton
              onClick={() => {
                handleClose();
              }}
              fhSize="md"
            >
              {confirmCTALabel}
            </FHButton>
          </div>
        )}
      </div>
    </Dialog>
  );
};
